import { useEffect, useState } from "react";
// import swal from "sweetalert";

import NewRecord from "./NewRecord";
//import extractErrorCode from './ErrorMessage';
import NoPrize from "./Modals/NoPrize";
// import Deposited from "./Modals/Deposited";
import loadingg from '../assets/Indexx-Fortune.gif'
import pfp from '../assets/pfp.png'
import pfp2 from '../assets/pfp2.png'
// import DisplayMessage from "../../../fortune_rush/src/components/Alert/DisplayAlert";
import { useNavigate } from "react-router-dom";
import DisplayMessage from "../../src/components/DisplayMessage";

const UserProfile = ({ indexxFortuneLottery, account, provider, switchNetwork }) => {
  // const [isModalOpen2, setIsModalOpen2] = useState(false);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [lastParticipatedLottery, setLastParticipatedLottery] = useState(false);
  const [numOfTicketsInLastLottery, setNumOfTicketsInLastLottery] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("Oops!")
  const [msg, setMsg] = useState("");
  // const [isModalOpen4, setIsModalOpen4] = useState(false);
  function extractErrorCode(str) {

    console.log("in extract", str);

    let ans = "An error occured";
    let firstOccurence, secondOccurence;
    let strmsg = str.toString();
    if (strmsg.includes("is not a function")) {
      ans = "Kindly connect your wallet again";

    }
    else if (strmsg.includes('Nonce too high')) {
      console.log("Nonce too high");
      ans = '\t\tNonce is too High\n Reset your acc using: \n settings-> Advanced-> Reset your account';

    }
    else {
      str = JSON.stringify(str);
      console.log(str)
      if (str.includes("NetworkError when attempting to fetch resource")) {
        ans = "Network Error: Binance Smart Chain Network unavailable.\n Reload and try again later";

      } else if (str.includes("MetaMask is having trouble connecting to the network")) {
        ans = "MetaMask is having trouble connecting to the network.\n Try again later";

      } else if (str.includes("user rejected transaction")) {
        ans = "We are sorry that you had to reject the transaction. Don't miss out on your chance to see your reward. Hurry up to and check your reward!!"
      }
      else if (str.includes("___")) {
        const delimiter = '___'; //Replace it with the delimiter you used in the Solidity Contract.
        firstOccurence = str.indexOf(delimiter);
        secondOccurence = str.indexOf(delimiter, firstOccurence + 1);
        if (secondOccurence === -1);
        else {
          ans = str.substring(firstOccurence + delimiter.length, secondOccurence);

        }
      } else {

        var j = JSON.parse(str);
        if (j.reason) {
          var reason = j["reason"].substring(27);
          console.log(reason);
          ans = reason;
        }
      }
    }
    setIsModalOpen(true);
    setMsg(ans);
    if (ans.includes('You have claimed the reward')) {
      setTitle("Good Job!");
    } else
      setTitle("Oops!");
    console.log("Erorr: in edit new ", ans);
  }
  const handleSeePrice = async () => {

    setLoading(true);
    // console.log("CLicked price");
    // try {
    //     const x = await switchNetwork();
    //     if(x===0){
    //       setLoading(false);
    //       return;
    //     }
    // } catch (err) {
    //   console(err);
    //   // return;
    // }
    console.log("Why here");
    let txn, index;
    const lotteryId = localStorage.getItem('lastParticipatedLottery');
    console.log("Price lid", lotteryId);

    // check if the lottery is claimable or not
    txn=await indexxFortuneLottery.viewLottery(lotteryId);
    txn=parseInt(txn[0].toString(),10);
    console.log("Status: ",txn);
    // not claimable

    if(txn!==3){
      setMsg("Lottery is currently not claimable. Kindly wait for the numbers to be drawn.")
      setTitle("Good Luck");
      setIsModalOpen(true);
      setLoading(false);
      return;

    }

    var ticketIds = [];
    try {
      // ticketIds of last participated lottery
      const max = localStorage.getItem('maxTicket');
      for (index = 0; index < max; index++) {

        txn = await indexxFortuneLottery._userTicketIdsPerLotteryId(account, lotteryId, index);
        const ticketId = parseInt(txn.toString(), 10);
        ticketIds.push(ticketId);
      }
    } catch (err) {
      // extractErrorCode(err);
      if (index === 0) {
        // setIsModalOpen(true);

        console.log("No ticket:");
        // setTitle("Oops!");
        // setMsg("No Prizes to collect.... Better Luck next time");
        // setIsModalOpen(true);
        navigate('/Rewards', { state: { data: "Oops!" } })

      }
      console.log("over");
    }

    // for each ticket id we are finding max bracket 
    let i, j, amount = 0;
    let claimableTickets = [], claimableBracket = [];

    try {
      // user having tickets with ticket Id eg: (3,5,6)
      for (i = 0; i < ticketIds.length; i++) {
        // console.log("Ticket id", ticketIds[i]);

        let correctBracket = -1, curAmount = 0;

        // for ach ticket id we will check the max bracket is the user eligigb;e
        for (j = 0; j < 6; j++) {
          // console.log("Bracket ", j);

          txn = await indexxFortuneLottery.viewRewardsForTicketId(lotteryId, ticketIds[i], j)
          txn = parseInt(txn.toString(), 10);
          // no reward
          if (txn !== 0) {
            correctBracket = j;
            // amount += txn;
            curAmount = Math.max(curAmount, txn);
          }
        }
        amount += curAmount;
        if (correctBracket !== -1) {
          claimableBracket.push(correctBracket);
          claimableTickets.push(ticketIds[i]);
        }

      }

    }
    catch (error) {
      console.log(error);
      // extractErrorCode(error);
    }
    console.log("Claimable bracket: ", claimableBracket);
    console.log(claimableTickets);
    // No tickets bought in last round 
    // console.log("Done..");
    if (claimableTickets.length <= 0) {
      // swal("Oops", "No price");
      // displayMessage("Oops!", "No Prizes to collect.... Better luck next time.", 0); // no ticket bought
      setMsg("No Prizes to collect.... Better luck next time.")
      setTitle("Oops!");
      setIsModalOpen(true);
      console.log("!no prizes to collect.... Better luck next time...");
      setLoading(false);
      navigate('/Rewards', { state: { data: "Oops!" } })
      return;
    }

    amount /= 1e18;
    amount = amount.toFixed(2);
    console.log("Amount to redirect ....", amount)

    try {
      txn = await indexxFortuneLottery.claimTickets(lotteryId, claimableTickets, claimableBracket);
      // console.log('TXN done ');

      provider.waitForTransaction(txn.hash)
        .then(async function (txn) {
          console.log('Transaction Mined: ' + txn.hash);
          // console.log(txn);
          // setIsModalOpen2(true);

          setMsg('Reward deposited in your wallet. Kindly check your wallet for INEX tokens.Deposited Amount: ' + amount);
          setTitle('Congratulations!');
          console.log("Amount to redirect ....", amount)
          // setIsModalOpen(true);
          setLoading(false);

          navigate('/Rewards', { state: { data: 'Congratulations!', amount: amount } })
        });
    } catch (err) {
      extractErrorCode(err);
      setLoading(false);

    }

  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (indexxFortuneLottery.signer) {
        setLastParticipatedLottery(localStorage.getItem('lastParticipatedLottery'));
        setNumOfTicketsInLastLottery(localStorage.getItem('numOfTicketsInLastLottery'));
      } else {
        navigate('/');
        return;
      }
    }, 1000);

    // don't forget to do clean up
    return () => clearInterval(interval);
  })

  return (
    <>
      <div class='text-[40px] flex justify-center text-grey py-9'><img alt="img" src={pfp} /><span class='mt-5 ml-3'>Your Profile</span></div>
      <div class='flex justify-center text-grey'>
        <div>
          <div class='w-[758px] h-[full] border border-grey border-opacity-50 p-12 rounded-sm'>
            <div class='text-[26px]'>Participated Round: {lastParticipatedLottery}</div>
            <div class='text-[23px] mt-7'>You have {numOfTicketsInLastLottery} ticket. Click to see if you have won any prize.</div>
            <div class='flex justify-center mt-16'>
              <button onClick={handleSeePrice} disabled={loading}  class='bg-[#7701FF] hover:bg-[#5100AD] text-white text-[25px] w-[340px] h-[61px] rounded-sm'>See Prize!</button>
            </div>
            {
              loading ? (
                <center>
                  <img
                    visible={true}
                    height="80"
                    width="80"
                    alt="img"
                    src={loadingg}
                    class="mt-12"
                  />
                </center>
              ) : (
                <></>
              )
            }
          </div>
          <div class='flex justify-center my-24 w-full'><img width="100%" src={pfp2} alt='ppl' /></div>

        </div>
        {
          // <Link class="bg-binance hover:bg-hover my[25px] px-14 py-1 text-center rounded-sm text-whitebg text-[22px]" to='/Rewards' >
          // test</Link>
        }
      </div>

      <NewRecord indexxFortuneLottery={indexxFortuneLottery} account={account} switchNetwork={switchNetwork} extractErrorCode={extractErrorCode} />

      <NoPrize
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      {
        // <Deposited
        // isVisible={isModalOpen2}
        // onClose={() => setIsModalOpen2(false)}
        // />
      }
      <DisplayMessage onClose={() => setIsModalOpen(false)} isVisible={isModalOpen} title={title} message={msg} />

    </>
  );
};

export default UserProfile;