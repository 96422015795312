import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ethers } from 'ethers';
import { isChrome, isEdge, isFirefox, isIE, isOpera } from "react-device-detect";

import './App.css';
import Home from "./Pages/Home";
import Buy from "./Pages/Buy";
import Header from "./components/Header";
import React, { useState } from 'react';
import IndexxFortuneLotteryAddress from './contractsData/IndexxFortuneLottery-address.json';
import IndexxFortuneLotteryAbi from './contractsData/IndexxFortuneLottery.json';
import UserProfile from "./components/UserProfile";
import EditNumbers from "./components/EditNumbers";
import Footer2 from "./components/Footer2";
import BSC from "./components/Modals/BSC";
import Congrats from "./components/Congrats";
import DisplayMessage from "./components/DisplayMessage";
import HowToPlay from "./components/HowToPlay";
import ScrollToTop from "./ScrollToTop";
// import BoughtTicket from "./components/BoughtTicket";

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [account, setAccount] = useState(null);
  const [indexxFortuneLottery, setIndexxFortuneLottery] = useState({});
  const [provider, setProvider] = useState();
  const [title, setTitle] = useState("Oops!")
  const [msg, setMsg] = useState("");
  const [buttonText, setButtonText] = useState("Ok");
  const [navigation, setNavigation] = useState("");
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  // const { chain, chains } = useNetwork()

  const findBrowserAndSetNavigation = () => {
    // console.log("Browser: ");
    // console.log(browserName, browserVersion);
    // console.log(isFirefox, isEdge, isSafari, isChrome, isIE);
    if (isFirefox)
      setNavigation("https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/");
    else if (isEdge || isIE)
      setNavigation("https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US");
    else if (isChrome)
      setNavigation("https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn")
    else if (isOpera)
      setNavigation("https://addons.opera.com/en-gb/extensions/details/metamask-10/");
    // else if(isBrave)
      else
      setNavigation("https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn");
  }
  const switchNetwork = async () => {
    try {
      const x = await handleSwitchNetwork();
      if (x === 0) {
        // setMsg("We are currently available on BSC network only. Kindly switch to Binance Smart Chain (BSC) network.");
        // setTitle("Oops!");
        // setIsModalOpen(true);
        return 0;
      }
      else return 1;

    } catch (err) {
      console(err);
    }
  }
  const handleSwitchNetwork = async () => {
    console.log("in swtich net");
    try {
      let chainId = await window.ethereum.request({ method: 'eth_chainId' });
      chainId = parseInt(chainId.toString(), 16);
      console.log("Chain id: ", chainId)
      if (chainId !== 97) {
        // setIsModalOpen(true);
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x61",
              chainName: "BSC Testnet",
              rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
              blockExplorerUrls: [
                "https://explorer.binance.org/smart-testnet",
              ],
              nativeCurrency: {
                symbol: "BNB",
                decimals: 18,
              },
            },
          ],
        });
      }
      chainId = await window.ethereum.request({ method: 'eth_chainId' });
      chainId = parseInt(chainId.toString(), 16);
      console.log("Chain id: ", chainId)
      if (chainId !== 97) {
        console.log("xxx");
        // setMsg("We are currently available on BSC network only. Kindly switch to Binance Smart Chain (BSC) network.");
        // setTitle("Oops!");
        // setIsModalOpen(true);
        // switchNetwork();
        return 0;
      }
      return 1;
    } catch (err) {
      console.log(err);
      return 0;
    }

  }
  const web3Handler = async () => {
    console.log(window.ethereum);
    if (window.ethereum && window.ethereum.isMetaMask && !window.ethereum.isBraveWallet) {
      let chainId;
      try {
        chainId = await window.ethereum.request({ method: 'eth_chainId' });
        chainId = parseInt(chainId.toString(), 16);
        console.log("Chain id: ", chainId)
        if (chainId !== 97) {
          // setIsModalOpen(true);
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x61",
                chainName: "BSC Testnet",
                rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
                blockExplorerUrls: [
                  "https://explorer.binance.org/smart-testnet",
                ],
                nativeCurrency: {
                  symbol: "BNB",
                  decimals: 18,
                },
              },
            ],
          });
          // return;
        }
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        setAccount(accounts[0]);
        // Get provider from Metamask
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(provider);
        // Set signer
        const signer = provider.getSigner();

        window.ethereum.on('chainChanged', (chainId) => {
          // window.location.reload();
        });

        window.ethereum.on('accountsChanged', async function (accounts) {
          setAccount(accounts[0]);
          await web3Handler();
        });
        loadContracts(signer);
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("no metamask");
      setTitle("Oops!");
      setMsg("Metamask not installed or conflicted with other wallets");
      setIsModalOpen2(true);
      findBrowserAndSetNavigation();
      setButtonText("Install Metamask");
      return;
    }
    // console.log('Acc :', account);
    // swal('Successfully connected', '', 'success');


  };
  const loadContracts = async (signer) => {
    // console.log('in load Contract');

    const indexxFortuneLottery = new ethers.Contract(
      IndexxFortuneLotteryAddress.address,
      IndexxFortuneLotteryAbi.abi,
      signer
    );
    setIndexxFortuneLottery(indexxFortuneLottery);
    setTitle("Hurray!");
    setMsg("Successfully connected your wallet");
    setIsModalOpen2(true);
    // console.log('Loaded..', indexxFortuneLottery, account);
    let lotteryId = await indexxFortuneLottery.viewCurrentLotteryId();
    lotteryId = parseInt(lotteryId.toString(), 10);
    localStorage.setItem('lotteryId', lotteryId);
    console.log("Lotter Id", localStorage.getItem('lotteryId'));

  };
  // console.log(indexxFortuneLottery);
  return (
    <>
      <div>

        <BrowserRouter>

          <ScrollToTop />
          <Header web3Handler={web3Handler} account={account} indexxFortuneLottery={indexxFortuneLottery} />
          <Routes>
            <Route path="/*" element={<Home web3Handler={web3Handler} account={account} indexxFortuneLottery={indexxFortuneLottery} switchNetwork={switchNetwork} />} />
            <Route path="/Buy" element={<Buy web3Handler={web3Handler} indexxFortuneLottery={indexxFortuneLottery} account={account} switchNetwork={switchNetwork} />} />
            <Route path="/User-Profile" element={<UserProfile indexxFortuneLottery={indexxFortuneLottery} account={account} provider={provider} switchNetwork={switchNetwork} />} />
            <Route path="/View-Edit-Numbers" element={<EditNumbers indexxFortuneLottery={indexxFortuneLottery} account={account} provider={provider} switchNetwork={switchNetwork}/>} />
            <Route path="/Rewards" element={<Congrats indexxFortuneLottery={indexxFortuneLottery} account={account} provider={provider} />} />
            {/* <Route path="/Bought-Ticket" element={<BoughtTicket indexxFortuneLottery={indexxFortuneLottery} account={account} provider={provider} />} /> */}
            <Route path="/how-to-play" element={<HowToPlay indexxFortuneLottery={indexxFortuneLottery} account={account} provider={provider} />} />

          </Routes>
          <Footer2 />

        </BrowserRouter>

      </div>

      <BSC
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <DisplayMessage title={title} isVisible={isModalOpen2} message={msg} onClose={() => setIsModalOpen2(false)} navigation={navigation} buttonText={buttonText} />

    </>
  )
};

export default App;