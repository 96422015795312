//import Swal from "sweetalert2";
// import { , useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import check from "../assets/checkorange.png";
import cross from "../assets/x orange.png";
// import check from "../assets";

// import cross from '../assets/cross-resized.png'

function DisplayMessage({ isVisible, onClose, title, message, navigation, buttonText }) {
    if (!isVisible) return null;

    return (
        <div>
            {console.log("In displaymessage.js: ", message, navigation, buttonText, !navigation, !buttonText)}

            {
                title === "Oops!" ? (

                    <div class='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[3px] flex justify-center items-center'>
                        <div class='w-[500px] bg-white rounded border border-grey border-opacity-20 '>
                            <div class='flex justify-center pt-6'><img alt='img' width='100px' src={cross} /></div>
                            <div class='text-hover pb-6 pt-6 text-4xl text-center'>{title}</div>
                            <div class='text-grey pb-6 flex justify-center  text-xl text-center mx-8'>{message}</div>
                            {
                                (!buttonText && !navigation) ? (
                                    <div class='text-white mb-6 flex justify-center'>
                                        <button class='bg-binance hover:bg-hover w-[100%] mx-8 py-3 rounded-sm flex justify-center' onClick={() => onClose()}>Ok</button>
                                    </div>
                                ) : (
                                    (!navigation) ? (
                                        <div class='text-white mb-6 flex justify-center'>
                                            <button class='bg-binance hover:bg-hover w-[100%] mx-8 py-3 rounded-sm flex justify-center' onClick={() => onClose()}>{buttonText}</button>
                                        </div>
                                    ) : (
                                        navigation.includes("http") ? (
                                            <div class='text-white mb-6 flex justify-center'>
                                                <a class='bg-binance hover:bg-hover w-[100%] mx-8 py-3 rounded-sm flex justify-center' href={navigation} onClick={() => onClose()}>{buttonText}</a>
                                            </div>
                                        ) : (
                                            <div class='text-white mb-6 flex justify-center'>
                                                <Link to={navigation} class='bg-binance hover:bg-hover w-[100%] mx-8 py-3 rounded-sm flex justify-center' onClick={() => onClose()}>{buttonText}</Link>
                                            </div>
                                        )
                                    )
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <div class='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[3px] flex justify-center items-center'>
                        <div class='w-[500px] bg-white rounded border border-grey border-opacity-20'>
                            <div class='flex justify-center pt-6'><img alt='img' width='100px' src={check} /></div>
                            <div class='text-binance pb-6 pt-6 text-4xl text-center'>{title}</div>
                            <div class='text-grey pb-6 flex justify-center text-xl text-center mx-8'>{message}</div>
                            {/* <div class='text-white mb-6 flex justify-center'>
                                <button class='bg-binance hover:bg-hover w-[100%] mx-8 py-3 rounded-sm' onClick={() => onClose()}>Ok</button>
                            </div> */}
                            {
                                navigation && buttonText ? (
                                    <div class='text-white mb-6 flex justify-center'>
                                        <Link to={navigation} class='bg-binance hover:bg-hover w-[100%] mx-8 py-3 rounded-sm  flex justify-center' onClick={() => onClose()}>{buttonText}</Link>
                                    </div>
                                ) : (
                                    <div class='text-white mb-6 flex justify-center'>
                                        <button class='bg-binance hover:bg-hover w-[100%] mx-8 py-3 rounded-sm  flex justify-center' onClick={() => onClose()}>Ok</button>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                )
            }
        </div>
    )
}
export default DisplayMessage;