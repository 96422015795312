import img from '../../assets/x orange.png'

const BSC = ({ isVisible, onClose }) => {
    if (!isVisible) return null;

    return (
        <>
            <div class='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[3px] flex justify-center items-center'>
                <div class='w-[500px] bg-white rounded border border-grey border-opacity-20'>
                    <div class='flex justify-center pt-6'><img alt='img' width='100px' src={img} /></div>
                    <div class='text-grey pb-6 pt-6 text-lg text-center'>Oops!</div>
                    <div class='text-grey pb-6 text-sm text-center'>Connect to Binance Smart Chain Network</div>
                    <div class='text-white mb-6 flex justify-center'><button class='bg-binance hover:bg-hover w-[100%] mx-9 py-3 rounded-sm' onClick={() => onClose()}>Ok</button></div>
                </div>
            </div>
        </>
    );
}

export default BSC;