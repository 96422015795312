import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import ConnectWallet from "./Modals/ConnectWallet";
import loadingg from '../assets/Indexx-Fortune.gif'
import DisplayMessage from "./DisplayMessage";
//import swal from "sweetalert";

const NewRecord = ({ indexxFortuneLottery, account, switchNetwork, extractErrorCode }) => {
    // const [isModalOpen4, setIsModalOpen4] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState("Oops!")
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [claimPrizeLoading, setClaimPrizeLoading] = useState(false);
    // const mockData = [
    //     {
    //         lotterId: 121,
    //         numOfTickets: 2,
    //         ticketNumber: "1100000, 1200000",
    //         status: "Claimed"
    //     },
    //     {
    //         lotterId: 23,
    //         numOfTickets: 1,
    //         ticketNumber: "1200000",
    //         status: "Claimed"
    //     },
    //     {
    //         lotterId: 12231,
    //         numOfTickets: 1,
    //         ticketNumber: "1200000",
    //         status: "Claimed"
    //     },
    //     {
    //         lotterId: 1223231,
    //         numOfTickets: 1,
    //         ticketNumber: "1200000",
    //         status: "Claimed"
    //     },
    //     {
    //         lotterId: 898,
    //         numOfTickets: 1,
    //         ticketNumber: "1200000",
    //         status: "Claimed"
    //     },
    // ]
    const viewTicketNumber = (ticketNumber) => {
        let tempTicketNumbers = [];
        for (let index = 0; index < ticketNumber.length; index++) {
            let num = ticketNumber[index];
            num = num.toString();
            num = num.substring(1);
            num = num.split('').reverse().join('');
            num = " " + num;
            // if (index !== 0 && index % 8 === 0) num += "\n";
            tempTicketNumbers.push(num);
            // console.log("Num reversed: ", num);
        }
        return tempTicketNumbers;

    }
    const calcReward = async (lotteryId) => {
        console.log("Calc reward ", lotteryId);
        let ticketIds = [], amount = 0, index, i, j, txn;
        try {
            // ticketIds of last participated lottery
            for (index = 0; index < 1e5; index++) {
                txn = await indexxFortuneLottery._userTicketIdsPerLotteryId(account, lotteryId, index);
                const ticketId = parseInt(txn.toString(), 10);
                ticketIds.push(ticketId);
            }
        } catch (err) {
            // extractErrorCode(err);
            if (index === 0) {
                console.log("No tickettttttt");
                return 0;
            }
            console.log(err);
        }
        console.log()
        try {
            // user having tickets with ticket Id eg: (3,5,6)
            for (i = 0; i < ticketIds.length; i++) {
                console.log("Ticket id", ticketIds[i]);
                let curAmount = 0;
                // for each ticket id we will check the max bracket is the user eligible
                for (j = 0; j < 6; j++) {
                    console.log("Bracket ", j);
                    txn = await indexxFortuneLottery.viewRewardsForTicketId(lotteryId, ticketIds[i], j)
                    txn = parseInt(txn.toString(), 10);

                    // no reward
                    if (txn !== 0) {
                        console.log("in bracket: ", ticketIds[i], txn, j);
                        // amount += txn;
                        curAmount = Math.max(curAmount, txn);
                    }
                }
                amount += curAmount;
                console.log("Amt: ", amount, lotteryId, ticketIds[i]);
            }
        } catch (error) {
            console.log("Error: cr", error);
        }
        amount /= 1e18;
        return amount.toFixed(2);

    }
    const listHistory = async () => {
        setIsDisabled(true);

        try {
            let x = 0;
            while (x === 0) {
                x = await switchNetwork();
            }
        } catch (err) {
            console(err);
            // return;
        }
        const data = [];
        const total = await indexxFortuneLottery.currentLotteryId();
        const intTot = parseInt(total.toString(), 10);
        // console.log("total Lotteries: ", intTot);
        let lastParticipatedLottery = 0, numOfTicketsInLastLottery = 0;
        for (let index = 1; index <= intTot; index++) {
            // users with account
            let txn = await indexxFortuneLottery.viewUserInfoForLotteryId(account, index, 0, 1e8);
            const ticketIds = txn[0].toString();
            console.log(ticketIds);
            // ticket id bought in lottery=index
            let tempTicketNumbers = txn[1];

            // user bough tickets
            if (tempTicketNumbers.length > 0) {
                lastParticipatedLottery = index;
                numOfTicketsInLastLottery = tempTicketNumbers.length;
                txn = await indexxFortuneLottery.viewLottery(index);
                // console.log("Lottery ", index, txn);
                txn = txn[0];
                // console.log("Status", txn);
                let _status = "Round is ";
                if (txn === 0) { _status += "Pending"; }
                else if (txn === 1) _status += "Open";
                else if (txn === 2) _status += "close";
                else _status += "Claimable";

                // reward in each round
                const amount = await calcReward(index);

                // view the org ticket number
                tempTicketNumbers = viewTicketNumber(tempTicketNumbers);
                // console.log("got", tempTicketNumbers);
                data.push({
                    lotterId: index,
                    numOfTickets: tempTicketNumbers.length,
                    ticketNumber: tempTicketNumbers.toString(),
                    status: _status,
                    amount: amount,
                    ticketId: ticketIds,

                });
            }
        }
        localStorage.setItem('lastParticipatedLottery', lastParticipatedLottery);
        localStorage.setItem('numOfTicketsInLastLottery', numOfTicketsInLastLottery);
        console.log("Last Lottery count: ", numOfTicketsInLastLottery)
        setUserData(data);
        setLoading(false);
        setIsDisabled(false);

    }
    const claimPrize = async (lotteryId, ticketId, amount, status) => {
        setClaimPrizeLoading(true);
        console.log("stat", status);
        if (!status.includes("Claim")) {
            setMsg("Lottery is currently not claimable. Kindly wait for the numbers to be drawn.")
            setTitle("Good Luck");
            setIsModalOpen(true);
            setClaimPrizeLoading(false);
            return;
        }
        console.log("Amount: ", amount);
        if (amount === 0) {
            navigate('/Rewards', { state: { data: "Oops!" } })
            setClaimPrizeLoading(false);
            console.log("#no prizes to collect.... Better luck next time...");
            setLoading(false);
            return;
        }
        ticketId += ",";
        const ticketIds = ticketId.split(',');
        console.log("claiming prize: ", lotteryId, ticketIds);
        let i, j;
        let claimableTickets = [], claimableBracket = [];

        let txn;
        try {
            // user having tickets with ticket Id eg: (3,5,6)
            for (i = 0; i < ticketIds.length; i++) {
                console.log("ticket id: ", ticketIds[i]);
                // for each ticket id we will check the max bracket is the user eligible
                let correctBracket = -1;
                for (j = 0; j < 6; j++) {
                    txn = await indexxFortuneLottery.viewRewardsForTicketId(lotteryId, ticketIds[i], j)
                    txn = parseInt(txn.toString(), 10);
                    console.log("j: ", j, " prize: ", txn);
                    // no reward
                    if (txn !== 0) {
                        correctBracket = j;
                    }
                }
                if (correctBracket !== -1) {
                    claimableBracket.push(correctBracket);
                    claimableTickets.push(ticketIds[i]);
                }
            }
        } catch (error) {
            console.log(error);
        }
        console.log("Claimable bracket: ", claimableBracket);
        console.log(claimableTickets);

        if (claimableTickets.length <= 0) {
            navigate('/Rewards', { state: { data: "Oops!" } })
            console.log("No Prize");
            setLoading(false);
            setClaimPrizeLoading(false);
            return;
        }

        console.log("Amount to redirect ....", amount)

        // claiming tickets
        try {
            // txn = await indexxFortuneLottery.claimTickets(lotteryId, claimableTickets, claimableBracket);
            await (await indexxFortuneLottery.claimTickets(lotteryId, claimableTickets, claimableBracket)).wait()

            console.log('TXN done ');
            navigate('/Rewards', { state: { data: "Congratulations!", amount: amount } })
            setClaimPrizeLoading(false);
        } catch (err) {
            console.log("Going: ");
            extractErrorCode(err);
            console.log("Came back");
            setClaimPrizeLoading(false);
        }

    }
    // const handleSwitch = async () => {
    //     try {
    //         let x = await switchNetwork();
    //         console.log(":", x);
    //         while(x === 0) {
    //             console.log("In while");
    //             setMsg("We are currently available on BSC test network only. Kindly switch to Binance Smart Chain (BSC) test network.");
    //             console("pop: ", msg)
    //             setTitle("Oops!");
    //             console("pop: ", title)

    //             setIsModalOpen(true);
    //             console("pop: ", isModalOpen)

    //             // x = await switchNetwork();
    //         }
    //     } catch (err) {

    //     }
    // }
    const handleSwitch = async () => {
        try {
            let x = await switchNetwork();
            console.log("LL: ", x);
            if (x === 0) {
                setMsg("We are currently available on BSC test network only. Kindly switch to Binance Smart Chain (BSC) test network.");
                console.log("pop", msg);
                setTitle("Oops!");
                console.log("pop", title);

                setIsModalOpen(true);
                console.log("pop", isModalOpen);

                // x = await switchNetwork();
                // return;
            }
        } catch (err) {

        }
    }
    useEffect(() => {
        if (indexxFortuneLottery.signer) {
            setLoading(true);
            listHistory();
            console.log("do");
        }
        else
            navigate('/');

        const interval = setInterval(() => {
            console.log("int");
            handleSwitch();
        }, 15000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [account]);

    if (loading) {
        return (
            <center>
                <img
                    visible={true}
                    height="100"
                    width="100"
                    alt="img"
                    src={loadingg}
                />
            </center>
        )
    }
    return (
        <>
            <div class='flex justify-center text-grey mt-24'>
                {
                    // <div class='w-[1217px] h-[407px] border border-grey border-opacity-20 p-12 rounded-sm'>
                    //     <div class='text-[26px]'>Your History:</div>
                    //     <div class='text-[20px] w-[319px] py-[24px]'>Id: </div>
                    //     <div class='text-[20px] w-[319px]'>Number of rounds played: </div>
                    //     <div class='text-[20px] w-[319px] py-[24px]'>Number of tickets bought:</div>
                    //     <div class='text-[20px] w-[319px]'>Prize won: </div>
                    // </div>
                }
                <div class='text-[40px] text-center'>
                    Your History

                    <div class='flex justify-center flex-wrap-reverse text-left'>

                        {
                            //mockData.map((data) => (
                            (userData.length > 0) ? (
                                userData.map((data) => (
                                    <div class=' w-[758px] mx-20 my-4 px-12 py-8 border text-hover border-grey border-opacity-40  hover:border-hover '>
                                        <div class='text-[20px] w-[319px] py-[24px] '>Lottery Id: <span class='text-grey'>{data.lotterId}</span></div>
                                        <div class='text-[20px] w-[319px]'>Status:<span class='text-grey'> {data.status}</span> </div>
                                        <div class='text-[20px] w-[319px] py-[24px]'>Number of Tickets: <span class='text-grey'>{data.numOfTickets} </span> </div>
                                        <div class='text-[20px] w-[650px]'>Ticket numbers: <span class='text-grey'>{data.ticketNumber}</span></div>
                                        <div class='text-[20px] w-[319px] py-[24px]'>Reward Earned: <span class='text-grey'> {data.amount} INEX</span> </div>
                                        <div class='flex justify-center my-2'>
                                            {
                                                isDisabled ? (
                                                    <button disabled="true" class='bg-grey  text-white text-[20px] w-[270px] h-[61px]'>Claim Prize</button>
                                                ) : (
                                                    <button disabled={claimPrizeLoading} onClick={(e) => claimPrize(data.lotterId, data.ticketId, data.amount, data.status)} class='bg-binance hover:bg-hover text-white text-[20px] w-[270px] h-[61px]'>Claim Prize</button>
                                                )
                                            }
                                        </div>
                                        {
                                            claimPrizeLoading ? (
                                                <center>
                                                    <img
                                                        visible={true}
                                                        height="80"
                                                        width="80"
                                                        alt="img"
                                                        src={loadingg}
                                                        class="pt-4"

                                                    />
                                                </center>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </div>
                                ))
                            ) : (
                                <div class=' flex  justify-center flex-col m-3  '>

                                    <span class='text-[30px] text-center  my-[25px]'>No tickets bought yet </span>
                                    <Link to='/Buy' class='bg-binance hover:bg-hover my[25px] px-14 py-1 text-center rounded-sm text-whitebg text-[22px] '>
                                        Buy Tickets
                                    </Link>
                                </div>
                            )
                        }
                    </div>
                </div>


                {/* <ConnectWallet
                isVisible={isModalOpen4}
                // onClose={() => setIsModalOpen4(false)}
            /> */}

            </div>
            <DisplayMessage onClose={() => setIsModalOpen(false)} isVisible={isModalOpen} title={title} message={msg} />

        </>
    )
}

export default NewRecord;