import img from '../../assets/x orange.png'

const Meta = ({isVisible, onClose}) => {
    if (!isVisible) return null;

    return (
        <>
            <div class='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[3px] flex justify-center items-center'>
                <div class='w-[500px] bg-white rounded border border-grey border-opacity-20'>
                    <div class='flex justify-center pt-6'><img alt='img' width='100px' src={img} /></div>
                    <div class='text-hover pb-6 pt-6 text-4xl  text-center'>Oops!</div>
                    <div class='text-grey pb-6 text-xl text-center mx-8'>Please connect your metamask account before buying ticket.</div>
                    <div class='text-white mb-6 flex justify-center'><button class='bg-binance hover:bg-hover w-[100%] mx-9 py-3 ' onClick={()=> onClose()}>Ok</button></div>
                </div>
            </div>
        </>
    );
}

export default Meta;